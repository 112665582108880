<template>
  <div class="container">
    <p class="title">
      <!-- <span class="required">*</span> -->
      <img class="time" src="~@img/cardInfo/预约时间.svg" alt="" />
      <span class="text">{{ title }}</span>
      <span class="tip">{{ tip }}</span>
    </p>

    <!-- 天 -->
    <div class="days">
      <div
        v-for="item in daysList"
        :key="item.date"
        :class="{ selected: selectedDay.date === item.date }"
        @click="handleClickDay(item.date)"
        class="day"
      >
        <p class="date">
          {{ item.dateStr }}
        </p>
        <p class="week">
          {{ item.week }}
        </p>
      </div>
    </div>

    <!-- 小时 -->
    <div class="range_wrapper">
      <div
        v-for="(item, key) in selectedDay.times"
        :key="item.time"
        :class="{
          selected: selectedTime.time === item.time,
          fully:
            (Number(
              isReview &&
                infusionDate === selectedDay.date &&
                selectedTime.time === item.time
                ? Number(item.numOfReservation) - 1
                : item.numOfReservation,
            ) >= Number(item.timeOfMum) &&
              Number(item.timeOfMum) > 0) ||
            computeLastTime(item.time),
          top_left: key === 0,
          bottom_right: key === selectedDay.times.length - 1,
          bottom_left:
            (key + 1) % 4 === 1 &&
            key >= selectedDay.times.length - (selectedDay.times.length % 4),
          top_right: key === Math.min(selectedDay.times.length, 4) - 1,
        }"
        @click="handleClickTime(item.time, item)"
        class="range"
      >
        <p class="text">
          {{ item.time }}
        </p>
        <p
          class="fullText"
          v-if="Number(item.numOfReservation) === Number(item.timeOfMum)"
        >
          已约满
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import moment from 'moment';

export default {
  name: 'InfisionTimeSelect',
  props: {
    title: {
      required: false,
      type: String,
      default: () => '预约时间',
    },
    tip: {
      required: false,
      type: String,
      default: () => '预约时间以输注中心确认结果为准',
    },

    // 日期列表
    daysList: {
      required: true,
      type: Array,
      default: () => [
      ],
    },
    infusionDate: {
      required: false,
      type: String,
      default: () => '',
    },
    infusionTimeCode: {
      required: false,
      type: Number | String,
      default: () => undefined,
    },

    // 是否是只读模式
    isReview: {
      required: false,
      type: Boolean,
      default: () => false,
    }
  },
  data () {
    return {
      // 选中的日期
      selectedDay: [],
      // 选中的时间
      selectedTime: {},
      isFully: false,
      isTimeBeyond: false
    };
  },
  watch: {
    'infusionTimeCode': {
      handler (val, oldVal) {
        console.log('watch infusionTimeCode', val);
      },
      immediate: true,
    },

    'infusionDate': {
      handler (newV, oldV) {
        if (newV) {
          if (!this.daysList.length) {
            return;
          }

          const res = this.daysList.find((item) => newV === item.date);
          if (res) {
            this.selectedDay = res;
            const selectedTime = this.selectedDay.times.find((currentTime) => String(this.infusionTimeCode) === String(currentTime.timeCode));
            if (selectedTime) {
              this.selectedTime = selectedTime;

              // 计算是否满额
              this.isFully = (Number(this.isReview ? Number(this.selectedTime.numOfReservation) - 1 : this.selectedTime.numOfReservation) >= Number(this.selectedTime.timeOfMum) && Number(this.selectedTime.timeOfMum) > 0)

              // 计算是否超出时间
              this.isTimeBeyond = this.computeLastTime(this.selectedTime.time)
            }
          }
        }
      },
      immediate: true,
    },

    daysList (newV) {
      if (newV) {
        // 找到当前选择的这一天
        const res = newV.find((item) => this.infusionDate === item.date);
        if (res) {
          // 设置当前天
          this.selectedDay = res;
          const selectedTime = this.selectedDay.times.find((currentTime) => String(currentTime.timeCode) === String(this.infusionTimeCode));
          if (selectedTime) {
            this.selectedTime = selectedTime;
            this.initTime = selectedTime
            // 是否满额
            this.isFully = (Number(this.isReview ? Number(this.selectedTime.numOfReservation) - 1 : this.selectedTime.numOfReservation) >= Number(this.selectedTime.timeOfMum) && Number(this.selectedTime.timeOfMum) > 0)
            // 计算是否超出时间
            this.isTimeBeyond = this.computeLastTime(this.selectedTime.time)
          }
        }
        if (!this.isReview) {
          this.handleClickDay(newV[0]['date'])
          /*
          // 先默认第一个天
          if (newV[0]['date'] === moment().format('yyyy-MM-DD')) {
            // 是今天
            const suitableTime = newV[0].times.find(time => {
              return !this.computeLastHour(time.time) && time.timeOfMum - time.numOfReservation > 0
            })
            if (suitableTime) {
              this.handleClickDay(newV[0]['date'])
              this.handleClickTime(suitableTime.time, suitableTime)
            } else {
              // 循环第二天开始
              // 循环第一天开始
              for (let i = 1; i < newV.length; i++) {
                for (let j = 0; j < newV[i].times.length; j++) {
                  const time = newV[i].times[j];
                  if (time.timeOfMum - time.numOfReservation > 0) {
                    this.handleClickDay(newV[i]['date'])
                    this.handleClickTime(time.time, time)
                    break
                  }
                }
                if (newV[i].date === this.selectedDay.date) {
                  break
                }
              }
            }
          } else {
            // 循环第一天开始
            for (let i = 0; i < newV.length; i++) {
              for (let j = 0; j < newV[i].times.length; j++) {
                const time = newV[i].times[j];
                if (time.timeOfMum - time.numOfReservation > 0) {
                  this.handleClickDay(newV[i]['date'])
                  this.handleClickTime(time.time, time)
                  break
                }
              }
              if (newV[i].date === this.selectedDay.date) {
                break
              }
            }
          }
          */
        }
      }
    },
  },
  methods: {
    computeLastHour (time) {
      // time: 12:00 ～ 13:00
      // 获取当前的小时
      const currentDate = new Date();

      // 比如当前13:05
      // 当前的小时: 13
      const hours = currentDate.getHours();

      // 给定的起始时间 例如：12
      const itemTimeHour = time.split(':')[0];

      // 对比 ：选中的日期是当天，并且小时超过所选小时，则判断为是过去
      if (Number(itemTimeHour) <= Number(hours)) {
        return true;
      }
      return false;
    },
    computeLastTime (time) {
      // time: 12:00 ～ 13:00
      // 获取当前的小时
      const currentDate = new Date();

      // 比如当前13:05
      // 当前的小时: 13
      const hours = currentDate.getHours();

      // 给定的起始时间 例如：12
      const itemTimeHour = time.split(':')[0];

      // 对比 ：选中的日期是当天，并且小时超过所选小时，则判断为是过去
      if (Number(itemTimeHour) <= Number(hours) && this.selectedDay.date === moment().format('yyyy-MM-DD')) {
        return true;
      }
      return false;
    },
    handleClickDay (date) {
      const selectedDay = this.daysList.find((day) => day.date === date);

      if (selectedDay) {
        this.selectedDay = selectedDay;
      }
    },

    // 选择了时间
    handleClickTime (time, item) {
      // 不能选过去的
      if (this.computeLastTime(item.time)) {
        return Toast.fail('不能选择过去的时间段，请选择其他时间段！');
      }

      // 没有名额了
      if (Number(this.isReview && this.selectedDay.date === this.infusionDate && this.initTime.time === time ? Number(item.numOfReservation) - 1 : item.numOfReservation) >= Number(item.timeOfMum) && Number(item.timeOfMum) > 0) {
        return Toast.fail('所选时间段已经无剩余预约名额，请选择其他时间段！');
      }
      const selectedTime = this.selectedDay.times.find((currentTime) => time === currentTime.time);
      if (selectedTime) {
        this.selectedTime = selectedTime;
        this.$emit('selectTime', this.selectedDay, selectedTime);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #fff;
  padding: 11px 12px 17px 13px;
  box-sizing: border-box;
  .title {
    // width: 64px;
    margin-bottom: 9px;
    position: relative;
    height: 22px;
    font-size: 14px;
    // font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #3a3a3a;
    line-height: 22px;
    .tip {
      // width: 180px;
      margin-left: 8px;
      position: absolute;
      top: 3px;
      height: 18px;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
      vertical-align: text-top;
    }
    .text {
      font-size: 16px;
    }

    .required {
      height: 22px;
      font-size: 14px;
      font-weight: 400;
      color: #fa3e3e;
      line-height: 22px;
    }
    .time {
      height: 22px;
      vertical-align: text-bottom;
      // margin-top: 1px;
      margin-left: 2px;
      margin-right: 2px;
    }
  }

  .days {
    display: flex;
    margin-bottom: 8px;
    width: 100%;
    height: 55px;
    background: #fff6f1;
    border-radius: 6px;
    .day {
      position: relative;
      flex-grow: 1;
      box-sizing: border-box;
      padding-top: 10px;
      padding-bottom: 9px;
      text-align: center;

      &.selected {
        background: #f36218;
        border-radius: 3px;
        .date,
        .week {
          color: #fff;
        }
        &::after {
          display: none;
        }
      }
      //   border-right: 1px solid #fff;
      // box-shadow: inset 0 0 0 5px #ccc;
      &:nth-child(7) {
        border-right: none;
      }

      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 20px;
        right: 0;
        width: 1px;
        height: 16px;
        background: #fff;
      }

      &:nth-child(7) {
        &::after {
          display: none;
        }
      }
      .date {
        // display: inline-block;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        margin-bottom: 2px;
      }
      .week {
        // display: inline-block;
        height: 14px;
        font-size: 10px;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
      }
    }
  }

  .range_wrapper {
    width: 100%;
    border-radius: 6px;
    background: #fff;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1px;

    .range {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 87px;
      height: 56px;

      background: #fff6f1;
      position: relative;
      .fullText {
        position: absolute;
        bottom: 4px;
        // left: 8px;
        color: #8a8a8a;
      }
      &.selected {
        background: #f36218;
        .text {
          color: #fff;
        }
      }

      &.fully {
        background: #ebebeb;
        .text {
          color: rgba(0, 0, 0, 0.35);
        }
      }
      &.top_left {
        border-top-left-radius: 6px;
      }
      &.top_right {
        border-top-right-radius: 6px;
      }
      &.bottom_left {
        border-bottom-left-radius: 6px;
      }
      &.bottom_right {
        border-bottom-right-radius: 6px;
      }
      .text {
        // width: 67px;
        height: 17px;
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        line-height: 17px;
      }
    }
  }
}
</style>
