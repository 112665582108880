<template>
  <van-swipe-cell :disabled="swipeDisabled">
    <div class="item_wrapper">
      <p class="name">
        {{ item.name }}（{{ item.usualName }}） X
        {{ item.number || item.goodsNum }}
      </p>
      <p class="specs">规格：{{ item.specs }} 单位：{{ item.unitName }}</p>
      <p class="provider">生产企业：{{ item.providerName }}</p>
      <p class="drug">
        <span class="pharmacy">{{ item.fullName }}</span>
        <span @click="handleMakePhone" class="tel">{{ item.pharmacyTel }}</span>
      </p>
      <p class="address">地址：{{ item.pharmacyAddress }}</p>
    </div>
    <template #right>
      <van-button square type="danger" text="删除" @click="handleClickItem" />
    </template>
  </van-swipe-cell>
</template>

<script>


import { Dialog } from 'vant';

export default {
  name: 'SelectedDrugItem',
  props: {
    item: {
      type: Object,
      required: true,
      default () {
        return {
          phone: '18811510644'
        }
      }
    },
    swipeDisabled: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    }
  },

  methods: {

    // 打电话
    handleMakePhone () {
      window.location.href = `tel:${this.item.pharmacyTel}`
    },

    // 点击删除（左滑）
    handleClickItem () {
      Dialog.confirm({
        title: '提示',
        message: '确认删除商品吗?',
      })
        .then(() => {
          this.$emit('onRemoveItem', this.item)
        })
        .catch(() => {
          // on cancel
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.item_wrapper {
  padding: 15px;
  background: #fff;
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 15px; /* 这里的值相当于左padding */
    right: 15px; /* 这里的值相当于右padding */
    border-bottom: 1px solid #e5e5e5; /* 设置底部边框 */
  }
  .name {
    margin-bottom: 10px;
    // height: 20px;
    line-height: 20px;
    font-size: 15px;
    font-weight: 500;
    color: #4a4a4a;
  }
  .specs {
    margin-bottom: 2px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    font-weight: 400;
    color: #90949e;
  }
  .provider {
    margin-bottom: 6px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    font-weight: 400;
    color: #90949e;
    position: relative;
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 1px; /* 这里的值相当于左padding */
      right: 1px; /* 这里的值相当于右padding */
      border-bottom: 1px dashed #e9e9e9; /* 设置底部边框 */
    }
  }

  .drug {
    margin-bottom: 4px;
    // height: 18px;
    line-height: 18px;
    font-size: 12px;
    color: #666;
    display: flex;

    .pharmacy {
      flex: 1;
      &::before {
        top: 4px;
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url('./pharmacy.svg');
        background-size: 14px 14px;
        vertical-align: text-top;
        margin-right: 2px;
      }
    }
    .tel {
      width: 120px;
      text-align: right;
      &::before {
        top: 4px;
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url('./telephone@2x.png');
        background-size: 14px 14px;
        vertical-align: text-top;
        margin-right: 2px;
      }
    }
  }

  .address {
    color: #666;
  }
}

/deep/ .van-button--danger {
  height: 100% !important;
}
</style>