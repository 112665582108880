<template>
  <div class="upload-wrapper">
    <div class="upload-padding">
      <div class="title">
        <div class="title-front">
          <img class="upload_img" src="~@img/cardInfo/资料上传.svg" alt="" />
          <span>资料上传</span>

          <span v-if="!onlyShow" class="describle">可上传补充资料 最多5张</span>
        </div>
        <div v-if="needSupplementary" class="title-after">
          <button @click="handleSupplementary" class="btn">补充资料</button>
        </div>
      </div>
      <div class="content">
        <div
          v-for="(item, index) in photoList"
          :key="index"
          @click="preview(index, photoList)"
          class="photo-item"
        >
          <div class="photo-item-img">
            <img :src="item" alt="" />
            <div
              @click.stop="delPhoto(index)"
              v-if="needEdit"
              class="img-delete"
            >
              <i class="delete-icon"><van-icon name="cross" /></i>
            </div>
          </div>
        </div>

        <div
          v-if="photoList.length < 5 && needEdit"
          @click="handleChooseImg"
          class="upload-box"
        >
          <div class="upload-content">
            <div class="upload-img">
              <img src="~@img/photo.jpg" class="upload-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WxTool from '@/utils/wx-tool';
import { ImagePreview } from 'vant';

export default {
  name: 'Uploader',

  props: {
    // 是否需要编辑 默认ture可以编辑 仅需展示图片的情况下 例如详情就给false
    needEdit: {
      type: Boolean,
      default: true,
    },
    // 是否是补充资料 用来编辑 这种是弹窗形式 默认是false
    needSupplementary: {
      type: Boolean,
      default: false,
    },
    // 只是图片展示
    onlyShow: {
      type: Boolean,
      default: false,
    },
    photoList: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
    };
  },

  methods: {
    handleChooseImg () {
      const instance = WxTool.getInstance();
      const photoNum = this.photoList.length;
      const uploadN = 5 - photoNum;
      instance.chooseImage(uploadN, (res) => {
        console.log('chooseImage home', res);
        if (res && res.localIds && res.localIds.length > 0) {
          this.$emit('onChooseImg', res.localIds);
        }
      });
    },

    delPhoto (index) {
      this.$emit('onDelPhoto', index);
    },

    preview (index, images) {
      ImagePreview({
        images,
        startPosition: index,
      });
    },

    handleSupplementary () {
      this.$emit('onSupplementary');
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-wrapper {
  width: 100%;
  background: #fff;
  margin-bottom: 8px;
}
.upload-padding {
  padding: 0 16px;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  line-height: 42px;
  .title-front {
    font-weight: 500;
    color: #3a3a3a;
    font-size: 16px;
    padding: 6px 0 4px 0;
    .upload_img {
      height: 16px;
      vertical-align: text-top;
      margin: -1px 2px 0 0px;
    }
    .describle {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      padding-left: 4px;
    }
  }
  .title-after {
    margin-top: 4px;
    .btn {
      display: block;
      flex: 1;
      flex-shrink: 0;
      height: 20px;
      border-radius: 4px;
      border: none;
      text-align: center;
      font-size: 12px;
      color: #ffffff;
      background: #f36218;
    }
  }
}
.content {
  display: flex;
}
.photo-item {
  width: 60px;
  height: 60px;
  margin: 8px 8px 8px 0;
  background-color: #f7f8fa;
  position: relative;
  .photo-item-img {
    width: 60px;
    height: 60px;
    img {
      width: 100%;
      height: 100%;
    }
    .img-delete {
      position: absolute;
      top: 0;
      right: 0;
      width: 18px;
      height: 18px;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 0 0 0 14px;
      opacity: 0.6;
      .delete-icon {
        position: absolute;
        top: -4px;
        left: 0px;
        color: #fff;
        font-size: 20px;
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
      }
    }
  }
}

.upload-box {
  width: 60px;
  height: 60px;
  background-color: #f7f8fa;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 8px 0;
}
.upload-img {
  width: 22px;
  height: 22px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
